<template>
    <section class="advanced-form-elements new-updated-design">
        <vue-snotify></vue-snotify>
        <system_app_settings></system_app_settings>
        <br>
        <div class="row">
            <div class="col-md-12">
                <form class="forms-sample" @submit.stop.prevent="onSubmit">
                    <div class="card grid-margin">
                        <div class="card-header">
                            <h4 class="card-title">{{ $t('upload-logo') }}</h4>
                        </div>
                        <div class="card-body new-card-body">
                            <div class="row mt-4">
                                <div class="col-md-4">
                                    <img :src="url1" alt="" width="200px" height="100px" class="mb-1">
                                </div>
                                <div class="col-md-8">
                                    <b-form-group :label="$t('upload-file')" label-for="input8">
                                        <b-form-file ref="fileInput" class="mt-2"
                                            accept="image/png, image/jpeg"
                                            id="input8"
                                            :placeholder="$t('choose-file')"
                                            @change="onFileSelected1">
                                        </b-form-file>
                                    </b-form-group>
                                    <!--                                        <label for="">Image should be 50 * 50 or 27 * 27(.png, .jpeg)</label><br>-->
                                    <b-button class="btn-2 btn-delete" @click="removeAppImage('logo')">
                                        <span class="mdi mdi-delete mdi-24px"></span>
                                        <span>{{ $t('remove-image') }}</span></b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{{ $t('map-icon') }}</h4>
                        </div>
                        <div class="card-body new-card-body">
                            <div class="row mt-4">
                                <div class="col-md-4">
                                    <img :src="url" alt="" width="50px" height="50px" class="mb-1 mt-3">
                                </div>
                                <div class="col-md-8">
                                    <b-form-group :label="$t('upload-file')" label-for="8">
                                        <b-form-file ref="fileInput1" class="mt-2"
                                            accept="image/png, image/jpeg"
                                            id="input9"
                                            :placeholder="$t('choose-file')"
                                            @change="onFileSelected"
                                            :disabled="!uploadAllowed">
                                        </b-form-file>
                                        <label id="input-2-live-feedback" v-if="image_error" style="color:red;">
                                            {{ $t('Opps! invalid image dimensions') }}
                                        </label>
                                    </b-form-group>
                                    <label for="">{{ $t('image-should-be') }} 50 x 50 or 27 x 27(.png, .jpeg)</label><br>
                                    <b-button :disabled="!uploadAllowed" class="btn-2 btn-delete" @click="removeAppImage('stock_image')">
                                        <span class="mdi mdi-delete mdi-24px"></span>
                                        <span>{{ $t('remove-image') }}</span>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6"></div>
                            <div class="col-md-6">
                                <b-button type="submit" variant="success" class="mr-4 mb-3 float-right"
                                          :disabled="isDisabled">{{ $t('submit-btn') }}
                                </b-button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script lang="js">
    import VueTrix from "vue-trix";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import Vue from "vue";
    import CKEditor from '@ckeditor/ckeditor5-vue2';
    // import DecoupledDocument from '@ckeditor/ckeditor5-build-decoupled-document';
    import CkEditorBuild from 'ckeditor5-custom-build/build/ckeditor';
    import UploadAdapter from '@/UploadAdapter';
    import {validationMixin} from "vuelidate";
    import {required, minLength, email, requiredIf, sameAs, between} from "vuelidate/lib/validators";
    import VueSlideBar from 'vue-slide-bar'
    import system_app_settings from "./system_app_settings.vue";

    const options = {
        toast: {
            timeout: 2000,
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(CKEditor);
    Vue.use(Snotify, options)
    export default {
        name: 'textEditor',
        mixins: [validationMixin],
        components: {
            // VueSlideBar
            system_app_settings
        },
        data() {
            return {
                form: {
                    header_color: "",
                    font_color: "",
                    is_heatMap: false,
                    // training_complete: true,
                    // enable_acoustic: true,
                    require_approval: true,
                    require_lat_lang: true,
                    require_photo: true,
                    // require_weather: true,
                    latitude: "",
                    longitude: "",
                    default_map_zoom: "",
                    default_map: "mapbox://styles/mapbox/light-v10",
                    stock_image: [],
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                },
                sliderCustomzie: {
                    lineHeight: 10,
                    processStyle: {
                        backgroundColor: '#008ffb'
                    },
                    tooltipStyles: {
                        backgroundColor: '#008ffb',
                        borderColor: '#008ffb'
                    }
                },
                editorForm: {
                    editor_content: "",
                },
                editor: CkEditorBuild,
                editorConfig: {
                    extraPlugins: [this.uploader],
                    toolbar: {
                        items: [
                            'heading',
                            '|',
                            'fontSize',
                            'fontFamily',
                            'fontColor',
                            'fontBackgroundColor',
                            '|',
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                            '|',
                            'alignment',
                            '|',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'link',
                            'blockQuote',
                            'imageUpload',
                            'insertTable',
                            'mediaEmbed',
                            '|',
                            'undo',
                            'redo',
                        ]
                    }, language: 'cs',
                    image: {
                        toolbar: [
                            'imageTextAlternative',

                            'imageStyle:side',
                        ]
                    },
                    table: {
                        contentToolbar: [
                            'tableColumn',
                            'tableRow',
                            'mergeTableCells'
                        ]
                    },
                },
                selectedFile: null,
                selectedFile1: null,
                url: null,
                url1: null,
                image_error: false,
                client_url: null,
                isDisabled: false,
                isDisabled1: false,
                uploadAllowed: false,
            }
        },
        validations: {
            form: {
               
            }
        },
        methods: {
               saveMapStylee(value){                
                // this.map.remove();
                this.form.is_heatMap = true;
                document.getElementById('satellite-v9').checked = false;
            

            },
               saveMapStyleee(value){                
                // this.map.remove();
                this.form.is_heatMap = false;
                document.getElementById('heat-map').checked = false;
            

            },
            // onSubmitEditor() {
            //     this.isDisabled1 = true;
            //     API.updateEditorContent(
            //         this.editorForm,
            //         data => {
            //             this.isDisabled1 = false;
            //             this.$snotify.success(data.message, {
            //                 timeout: 2000,
            //                 showProgressBar: false,
            //                 closeOnClick: true,
            //                 pauseOnHover: true,
            //             });
            //         },
            //         err => {
            //             this.isDisabled1 = false;
            //             this.$snotify.error(err.message, {
            //                 timeout: 2000,
            //                 showProgressBar: false,
            //                 closeOnClick: true,
            //                 pauseOnHover: true,
            //             });
            //         }
            //     )
            // },
            onSubmit() {

                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }

                if (!this.image_error) {
                    const fd = new FormData();
                    if (this.selectedFile?.type && this.selectedFile?.type.indexOf('image') !== -1) {
                        fd.append('file', this.selectedFile, this.selectedFile.name)
                    }
                    else {
                        fd.append('file', null)
                    }

                    if (this.selectedFile1?.type && this.selectedFile1?.type.indexOf('image') !== -1) {
                        fd.append('file1', this.selectedFile1, this.selectedFile1.name)
                    }else {
                        fd.append('file1', null)
                    }

                    fd.append('data', JSON.stringify(this.form))
                    API.updateBrandSettings(
                        fd,
                        data => {
                            console.log(data);
                            this.isDisabled = false;
                            if(data.status === 200){
                                this.$v.form.$reset();
                                this.$snotify.success(data.message, {
                                    timeout: 2000,
                                    showProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                            else{
                                if(data.type === 'logo'){
                                    this.$refs.fileInput.$el.value=null;
                                    this.url = '';
                                    this.selectedFile = ''
                                }else if(data.type === 'icon'){
                                    this.$refs.fileInput1.$el.value=null;
                                    this.url1 = '';
                                     this.selectedFile1 = ''
                                }
                                this.$snotify.error(data.message, {
                                    timeout: 2000,
                                    showProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                        },
                        err => {
                            this.isDisabled = false;
                            this.$snotify.error(err.message, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    )
                } else {
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: 'Image size is not valid.',
                    })
                }
            },
            onFileSelected(event) {
                var img = new Image();
                var target_file = event.target.files[0];
                img.src = URL.createObjectURL(target_file);


                img.onload = () => {
                    console.log(img.naturalWidth, img.naturalHeight)
                    if ((img.naturalWidth === 50 && img.naturalHeight === 50) || (img.naturalWidth === 27 && img.naturalHeight === 27)) {
                        this.image_error = false;
                        this.selectedFile = event.target.files[0]
                        this.url = URL.createObjectURL(this.selectedFile)
                    } else {
                        this.image_error = true;
                    }
                }
            },
            onFileSelected1(event) {
                // var img = new Image();
                // var target_file = event.target.files[0];
                // img.src = URL.createObjectURL(target_file);


                // img.onload = () => {
                //     console.log(img.naturalWidth, img.naturalHeight)
                //     if ((img.naturalWidth === 50 && img.naturalHeight === 50) || (img.naturalWidth === 27 && img.naturalHeight === 27)) {
                //         this.image_error = false;
                        // if(event.target.files[0]['type']==='image/jpeg'){

                        // }
                        this.selectedFile1 = event.target.files[0]
                        console.log(this.selectedFile1)
                        this.url1 = URL.createObjectURL(this.selectedFile1)
                    // } else {
                    //     this.image_error = true;
                    // }
                // }
            },
            // getAppSettings() {
            //     const data = {
            //         client_id: JSON.parse(localStorage.getItem('client_id')),
            //     }
            //     API.getAppSettings(
            //         data,
            //         data => {
            //             this.url = data.data.image;
            //             this.url1 = data.data.logo;
            //         },
            //         err => {
            //         }
            //     )
            // },
            getAppSettings() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getAppSettings(
                    data,
                    data => {
                        this.editorForm.editor_content = data.data.editor_content;
                        this.form.header_color = data.data.header_color;
                        this.form.font_color = data.data.font_color;
                        this.form.latitude = data.data.latitude;
                        this.form.longitude = data.data.longitude;
                        this.form.default_map_zoom = data.data.default_map_zoom;
                        this.form.default_map = (data.data.default_map) ? data.data.default_map : 'mapbox://styles/mapbox/light-v10';
                        this.form.require_photo = (data.data.require_photo === 1) ? true : false;
                        // this.form.require_weather = (data.data.require_weather === 1) ? true : false;
                        this.form.require_lat_lang = (data.data.require_lat_lang === 1) ? true : false;
                        this.form.require_approval = (data.data.require_approval === 1) ? true : false;
                        // this.form.training_complete = (data.data.training_complete === 1) ? true : false;
                        // this.form.enable_acoustic = (data.data.enable_acoustic === 1) ? true : false;
                        this.url = data.data.image;
                        this.url1 = data.data.logo;
                    },
                    err => {
                    }
                )
            },
            removeAppImage(ref) {
                let data = {
                    file: ref,
                    client_id: this.form.client_id
                }
                this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to get this image back.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm!'
                }).then((result) => {
                    if (result.value) {
                        API.removeAppImage(
                            data,
                            data => {
                                if(ref == 'logo') {
                                    this.selectedFile1 = [];
                                    this.url1 = '';
                                } else if(ref == 'stock_image') {
                                    this.selectedFile = [];
                                    this.url = '';
                                }
                                this.$snotify.success(data.message);
                            },
                            err => {
                            }
                        )
                    }
                })
            },
        }
        ,
        beforeMount() {
            this.getAppSettings();
        }
    }
</script>
<style scoped>
    .document-editor {
        border: 1px solid var(--ck-color-base-border);
        border-radius: var(--ck-border-radius);

        /* Set vertical boundaries for the document editor. */
        max-height: 700px;

        /* This element is a flex container for easier rendering. */
        display: flex;
        flex-flow: column nowrap;
    }

    .document-editor__toolbar {
        /* Make sure the toolbar container is always above the editable. */
        z-index: 1;

        /* Create the illusion of the toolbar floating over the editable. */
        box-shadow: 0 0 5px hsla(0, 0%, 0%, .2);

        /* Use the CKEditor CSS variables to keep the UI consistent. */
        border-bottom: 1px solid var(--ck-color-toolbar-border);
    }
    /* Adjust the look of the toolbar inside the container. */
    .document-editor__toolbar .ck-toolbar {
        border: 0;
        border-radius: 0;
    }

    /* Make the editable container look like the inside of a native word processor application. */
    .document-editor__editable-container {
        padding: calc(2 * var(--ck-spacing-large));
        background: var(--ck-color-base-foreground);

        /* Make it possible to scroll the "page" of the edited content. */
        overflow-y: scroll;
    }

    .document-editor__editable-container .ck-editor__editable {
        /* Set the dimensions of the "page". */
        width: 15.8cm;
        min-height: 21cm;

        /* Keep the "page" off the boundaries of the container. */
        padding: 1cm 2cm 2cm;

        border: 1px hsl(0, 0%, 82.7%) solid;
        border-radius: var(--ck-border-radius);
        background: white;

        /* The "page" should cast a slight shadow (3D illusion). */
        box-shadow: 0 0 5px hsla(0, 0%, 0%, .1);

        /* Center the "page". */
        margin: 0 auto;
    }

    /* Set the default font for the "page" of the content. */
    .document-editor .ck-content,
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    /* Adjust the headings dropdown to host some larger heading styles. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        line-height: calc(1.7 * var(--ck-line-height-base) * var(--ck-font-size-base));
        min-width: 6em;
    }

    /* Scale down all heading previews because they are way too big to be presented in the UI.
    Preserve the relative scale, though. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
        transform: scale(0.8);
        transform-origin: left;
    }

    /* Set the styles for "Heading 1". */
    .document-editor .ck-content h2,
    .document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
        font-size: 2.18em;
        font-weight: normal;
    }

    .document-editor .ck-content h2 {
        line-height: 1.37em;
        padding-top: .342em;
        margin-bottom: .142em;
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3,
    .document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
        font-size: 1.75em;
        font-weight: normal;
        color: hsl(203, 100%, 50%);
    }

    .document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
        color: var(--ck-color-list-button-on-text);
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3 {
        line-height: 1.86em;
        padding-top: .171em;
        margin-bottom: .357em;
    }

    /* Set the styles for "Heading 3". */
    .document-editor .ck-content h4,
    .document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
        font-size: 1.31em;
        font-weight: bold;
    }

    .document-editor .ck-content h4 {
        line-height: 1.24em;
        padding-top: .286em;
        margin-bottom: .952em;
    }

    /* Set the styles for "Paragraph". */
    .document-editor .ck-content p {
        font-size: 1em;
        line-height: 1.63em;
        padding-top: .5em;
        margin-bottom: 1.13em;
    }

    /* Make the block quoted text serif with some additional spacing. */
    .document-editor .ck-content blockquote {
        font-family: Georgia, serif;
        margin-left: calc(2 * var(--ck-spacing-large));
        margin-right: calc(2 * var(--ck-spacing-large));
    }

    .ck.ck-editor__editable_inline {
        border: 1px solid #e3e3e3;
        overflow: auto;
        padding: 0 var(--ck-spacing-standard);
    }

.btn-2 {
  display: flex;
  align-items: center;
  --background: #2b3044;
  --background-hover: #1e2235;
  background: none;
  border: 1px solid lighten(gray, 24%);
  height: 48px;
  padding: 0 24px 0 16px;
  letter-spacing: .25px;
  border-radius: 18px;
  cursor: pointer;
}

.btn-delete {
  font-size: 12px;
  color: red;
}

.button-62 {
  background: linear-gradient(to bottom right, #EF4765, #FF9A5A);
  border: 0;
  border-radius: 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.5;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.btn-2:not([disabled]):focus {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

.btn-2:not([disabled]):hover {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}
@media (max-width: 1150px) {
    .card .new-card-body {
        padding: 1.25rem 1.437rem !important;
    }
}
</style>
